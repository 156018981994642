<template>
  <div>
    <h3 class="headline--md text-purple text-bold">Edit User Profile</h3>
    <form @submit.prevent="onSubmit" class="mt-32">
      <div class="form-group">
        <label class="form-label">Country Code</label>
        <input
          type="text"
          :class="['form-control', { 'form-control--error': $v.params.countryCode.$anyError }]"
          v-model="$v.params.countryCode.$model"
        />
        <p v-if="$v.params.countryCode.$anyError" class="form-error-text">
          <span v-if="!$v.params.countryCode.required">Country code can't be blank</span>
        </p>
      </div>

      <div class="form-group">
        <label class="form-label">Phone Number</label>
        <input
          type="text"
          :class="['form-control', { 'form-control--error': $v.params.phone.$anyError }]"
          v-model="$v.params.phone.$model"
        />
        <p v-if="$v.params.phone.$anyError" class="form-error-text">
          <span v-if="!$v.params.phone.required">Phone number can't be blank</span>
        </p>
      </div>

      <div class="form-group">
        <button class="btn btn--primary btn--full">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import joinStakingFormValidation from '../valiadtions/join-staking-form'
import { mapActions } from 'vuex'

export default {
  name: 'EditUserProfile',

  mixins: [joinStakingFormValidation],

  props: {
    profile: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      params: {
        phone: '',
        countryCode: ''
      }
    }
  },

  methods: {
    ...mapActions('staking', ['approveUserJoinStaking']),

    onSubmit() {
      this.$v.$touch()
      if (this.isFormValid) {
        this.approveUserJoinStaking({
          ...this.params,
          id: this.profile.id
        }).then(() => {
          this.$emit('success')
        })
      }
    }
  },

  mounted() {
    if (this.profile) {
      this.params.phone = this.profile.phone
      this.params.countryCode = this.profile.countryCode
    }
  }
}
</script>
